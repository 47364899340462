import React from 'react';
import { Trans } from 'react-i18next';

import { LOADING_OPTIONS, LinkWrapper, Text } from '@components';
import { COMPATIBLE_DEVICES } from '@shared/constants/pages/product-page-common';
import { META_IMAGE, PRICING_LINK } from '@shared/constants/constants';
import { APPS_LINKS } from '@shared/constants/apps';
import { SERVER_COUNT } from '@shared/constants/server-locations';
import { formatLink } from '@shared/format-helpers';
import { APP_PARAMS, GA4_EVENTS } from '@shared/constants/google';
import { getFetchedImage } from '@shared/image-helpers';
// Footer CTA images
import footerImage from '@images/product-page/common-footer-image.png';
import footerImageTablet from '@images/product-page/common-footer-image-tablet.png';
import footerImageTabletTop from '@images/product-page/common-footer-image-tablet-top.png';
import footerImageMobile from '@images/product-page/common-footer-image-mobile.png';
import footerImageMobileTop from '@images/product-page/common-footer-image-mobile-top.png';
// Steps Images
import Subscribe from '@images/product-page/upgrade-anytime.svg';
import DownloadApp from '@images/product-page/download-app.svg';
import ConnectEnjoy from '@images/product-page/connect-enjoy.svg';
// Perks images
import Privacy from '@images/product-page/privacy.svg';
import UnlimitedAccess from '@images/product-page/unlimited-access.svg';
import ZeroLogs from '@images/product-page/zero-logs.svg';
import HassleFree from '@images/product-page/hassle-free.svg';

const LinkComponent = ({ link, children }) => (
  <LinkWrapper link={link}>
    <Text.Body2 as="span" color="primary" fontWeight={700} fontSize="inherit">
      {children}
    </Text.Body2>
  </LinkWrapper>
);

export const PAGE_DATA = ({ images }) => ({
  metaData: (t) => ({
    title: t('vpnForAppleTv:metaData.title'),
    description: t('vpnForAppleTv:metaData.description'),
    image: META_IMAGE,
  }),
  headerCta: {
    text: ({ isLoading, discountPercentage, pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('vpnForAppleTv:headerCta.title'),
      subtitle: t('vpnForAppleTv:headerCta.subtitle'),
      description: [
        { title: t('vpnForAppleTv:headerCta.productFeatures.speedOptimized') },
        {
          title: t('vpnForAppleTv:headerCta.productFeatures.noDataCaps'),
        },
        { title: t('vpnForAppleTv:headerCta.productFeatures.locationChange') },
        { title: t('vpnForAppleTv:headerCta.productFeatures.noLogs') },
      ],
      buttons: [
        {
          title: t('common:actions.downloadFree'),
          link: APPS_LINKS.appleTv,
          buttonProps: {
            variant: 'outlined',
            customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.appleTv },
          },
        },
      ],
    }),
    image: () => ({
      mobile: {
        src: getFetchedImage(images, 'product-page/apple-tv-header-image-mobile.png'),
        alt: 'apple tv page header',
        loading: LOADING_OPTIONS.eager,
      },
      tablet: {
        src: getFetchedImage(images, 'product-page/apple-tv-header-image-tablet.png'),
        alt: 'apple tv page header',
        loading: LOADING_OPTIONS.eager,
      },
      desktop: {
        src: getFetchedImage(images, 'product-page/apple-tv-header-image.png'),
        alt: 'apple tv page header',
        loading: LOADING_OPTIONS.eager,
        sizes: '2830px',
        position: {
          top: -80,
          height: 720,
          maxWidth: 2830,
        },
      },
    }),
  },
  compatibleDevices: (t) => ({ cards: COMPATIBLE_DEVICES(t), mobileCards: COMPATIBLE_DEVICES(t) }),
  steps: ({ pricingLink = PRICING_LINK, t } = {}) => ({
    title: t('vpnForAppleTv:stepCards.title'),
    description: t('vpnForAppleTv:stepCards.description'),
    items: [
      {
        image: DownloadApp,
        step: 1,
        title: t('vpnForAppleTv:stepCards.getApp.title'),
        description: t('vpnForAppleTv:stepCards.getApp.description'),
      },
      {
        image: Subscribe,
        step: 2,
        title: t('vpnForAppleTv:stepCards.upgradeToPremium.title'),
        description: t('vpnForAppleTv:stepCards.upgradeToPremium.description'),
      },
      {
        image: ConnectEnjoy,
        step: 3,
        title: t('vpnForAppleTv:stepCards.connect.title'),
        description: t('vpnForAppleTv:stepCards.connect.description'),
      },
    ],
  }),
  benefits: ({ t, locationCount }) => ({
    title: t('vpnForAppleTv:smarterMorePrivate'),
    description: t('vpnForAppleTv:moreProtectionAndPrivacy'),
    data: [
      {
        isReversed: true,
        image: getFetchedImage(images, 'product-page/surf-online-smoothly.png'),
        title: t('vpnForAppleTv:contentBlocks.speedOptimized.title'),
        description: (
          <Trans
            i18nKey="vpnForAppleTv:contentBlocks.speedOptimized.description"
            values={{
              serverCount: SERVER_COUNT,
              locationCount,
            }}
            components={[<LinkComponent link="/servers" key={0} />]}
          />
        ),
        link: {
          title: t('common:links.waysToUseVpn'),
          link: formatLink(t('navigation:paths.use-cases')),
          customEvent: { eventName: GA4_EVENTS.waysToUseVpnClick },
        },
      },
    ],
  }),
  perksBefore: (t) => ({
    items: [
      {
        image: Privacy,
        title: t('vpnForAppleTv:perkCards.morePrivately.title'),
        description: t('vpnForAppleTv:perkCards.morePrivately.description'),
      },
      {
        image: UnlimitedAccess,
        title: t('vpnForAppleTv:perkCards.asLongAsYouLike.title'),
        description: t('vpnForAppleTv:perkCards.asLongAsYouLike.description'),
      },
    ],
  }),
  encryption: (t) => ({
    data: [
      {
        image: getFetchedImage(images, 'product-page/safety-for-activities.png'),
        title: t('vpnForAppleTv:contentBlocks.securityBenefits.title'),
        description: (
          <Trans
            i18nKey="vpnForAppleTv:contentBlocks.securityBenefits.description"
            components={[<LinkComponent link="/features/vpn-encryption" key={0} />]}
          />
        ),
      },
    ],
  }),
  perksAfter: ({ pricingLink = PRICING_LINK, t } = {}) => ({
    items: [
      {
        image: ZeroLogs,
        title: t('vpnForAppleTv:perkCards.noLogsPolicy.title'),
        description: t('vpnForAppleTv:perkCards.noLogsPolicy.description'),
      },
      {
        image: HassleFree,
        title: t('vpnForAppleTv:perkCards.beginnerFriendly.title'),
        description: t('vpnForAppleTv:perkCards.beginnerFriendly.description'),
      },
    ],
    link: {
      title: t('common:links.seeListFeatures'),
      link: formatLink(t('navigation:paths.features')),
      customEvent: { eventName: GA4_EVENTS.fullFeaturesClick },
    },
  }),
  reviews: (t) => ({
    title: t('vpnForAppleTv:reviews.title'),
  }),
  moneyBackGuarantee: (t) => ({
    data: [
      {
        image: getFetchedImage(images, 'product-page/30-day-guarantee.png'),
        title: t('common:moneyBackGuarantee.title'),
        description: t('common:moneyBackGuarantee14.description'),
      },
    ],
  }),
  pricing: ({ t }) => ({
    title: t('vpnForAppleTv:pricingSection.title'),
    description: t('vpnForAppleTv:pricingSection.description'),
    footerProps: {
      text: t('vpnForAppleTv:pricingSection.notReadyYet'),
      button: {
        link: APPS_LINKS.appleTv,
        title: t('common:actions.download'),
        buttonProps: {
          customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.appleTv },
        },
      },
    },
  }),
  faq: () => ({
    title: 'Frequently asked questions',
    items: [
      {
        header: 'Does Apple TV support VPNs?',
        content:
          'Yes, you can use a VPN on Apple TV by installing the app directly on your device. So, you can head to the App Store and find a VPN suitable for your activities.\n\nWe recommend trying Atlas VPN! You can improve your privacy and access more content securely. So, boost your viewing experience on Apple TV for a budget-friendly price of Atlas VPN Premium.',
      },
      {
        header: 'Does Atlas VPN have a free plan for Apple TV?',
        content:
          'Atlas VPN on Apple TV has a free and premium version. So, you can use our VPN for free on Apple TV.',
      },
      {
        header: 'What are the benefits of using a VPN on Apple TV?',
        content:
          'VPNs for Apple TV aim to provide you with a safer and more private experience on this device.\n\nThus, you get to protect your habits and connections from some unwanted tracking and interruptions. The latter can refer to Internet Service Providers trying to slow down connections for bandwidth-heavy activities.\n\nVPN services encrypt internet traffic, preventing ISPs from seeing what you do. As a result, they should not be able to perform type-based throttling.',
      },
      {
        header: 'What other smart TVs does Atlas VPN support?',
        content: (
          <span>
            Atlas VPN supports <LinkComponent link="/vpn-for-android-tv">Android TV</LinkComponent>,{' '}
            <LinkComponent link="/vpn-for-fire-tv">Amazon Fire TV/FireStick</LinkComponent>, and
            Apple TV. It means that you can install Atlas VPN directly on these devices.
            <br />
            <br />
            However, some digital media players might not support VPNs. For instance,{' '}
            <LinkComponent link="/blog/how-to-use-vpn-on-roku">Roku does not let</LinkComponent> you
            install a VPN directly. Thus, VPN providers and users need workarounds. One such option
            is to share your connection from a{' '}
            <LinkComponent link="/blog/steps-for-turning-your-computer-into-a-hotspot">
              mobile hotspot
            </LinkComponent>
            .
          </span>
        ),
      },
      {
        header: 'How many devices can you connect to Atlas VPN simultaneously?',
        content:
          'Atlas VPN does not limit simultaneous connections. Thus, you can connect to Atlas VPN servers on smartphones, computers, and TVs at the same time.',
      },
    ],
  }),
  footerCta: {
    text: ({ pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('vpnForAppleTv:footerCta.title'),
      description: t('vpnForAppleTv:footerCta.description'),
    }),
    image: () => ({
      mobile: {
        src: footerImageMobile,
        alt: 'apple tv page footer',
      },
      mobileTop: {
        src: footerImageMobileTop,
        alt: 'apple tv page footer',
      },
      tablet: {
        src: footerImageTablet,
        alt: 'apple tv page footer',
      },
      tabletTop: {
        src: footerImageTabletTop,
        alt: 'apple tv page footer',
      },
      desktop: {
        src: footerImage,
        alt: 'apple tv page footer',
        position: {
          bottom: 0,
          height: 540,
          maxWidth: 3000,
        },
      },
    }),
  },
});
